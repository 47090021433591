import React from "react";
import appicon from "../pictures/appicon.png";

function Header() {
  return (
    <>
      <img src={appicon} alt="" />
    </>
  );
}

export default Header;
